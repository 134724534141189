import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "row items-center no-wrap",
  style: {"gap":"14px"}
}
const _hoisted_2 = {
  class: "text-large",
  style: {"flex-grow":"1"}
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "text-small text-grey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_large_btn = _resolveComponent("large-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "rounded-borders-large perk-card shadow-10",
    i18n: _ctx.$options.name
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, {
        class: "row column full-height",
        style: {"gap":"20px"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_q_icon, {
              size: "50px",
              name: 'img:' + _ctx.logoPath,
              class: "q-mr-none"
            }, null, 8, ["name"]),
            _createElementVNode("h3", null, _toDisplayString(_ctx.name), 1)
          ]),
          _createVNode(_component_q_img, {
            src: _ctx.imagePath,
            width: "100%",
            fit: "contain"
          }, null, 8, ["src"]),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.description()), 1),
          _createElementVNode("div", null, [
            _createElementVNode("p", {
              innerHTML: _ctx.t('perks.discount', { discountValue: _ctx.discount }),
              class: "text-large"
            }, null, 8, _hoisted_3),
            (_ctx.validUntil)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.t('perks.validUntil', { validUntilDate: _ctx.validUntil })), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_q_btn, {
              label: _ctx.promoCode,
              icon: _ctx.matContentCopy,
              class: "q-mt-md text-large",
              color: "grey-3",
              padding: "sm",
              "no-caps": "",
              "text-color": "black",
              unelevated: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doCopy(_ctx.promoCode)))
            }, null, 8, ["label", "icon"])
          ]),
          _createVNode(_component_large_btn, {
            href: _ctx.offerLink,
            label: _ctx.t('perks.goToOffer'),
            type: "a",
            target: "_blank",
            rel: "noopener noreferrer nofollow",
            class: "text-large",
            fill: "",
            color: "accent"
          }, null, 8, ["href", "label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["i18n"]))
}