import { computed, ref, Ref, watch } from 'vue'
import { useMeta } from 'quasar'
import { NamedValue } from 'vue-i18n'
import { BreadcrumbEntry, Container, FAQEntry } from 'src/model/common.model'
import { Language } from 'src/model/language.model'
import { useI18n$ } from 'boot/i18n'
import {
  createArticleJsonLd,
  createBreadcrumbJsonLd,
  createDescriptionMetaHeader,
  createFAQJsonLd,
  createImageJsonLd,
  createLanguageLinks,
  createMetaHeaders,
  createOrganizationJsonLd,
  createPreloadImages,
  createProductJsonLd,
  createSelfStorageJsonLd,
  createWebSiteJsonLd,
  HasBody,
  HasCity,
  HasCoordinates,
  HasCountry,
  HasCreatedDate,
  HasCurrency,
  HasImage,
  HasPrice,
  HasPriceValue,
  HasTotalReview,
  MayHaveImage,
  MetaHeaderConfiguration,
  PathEntry,
} from 'src/functions/seo'
import { useRouter } from 'vue-router'
import { ALLOWED_LANGUAGES, SITE_URL } from 'src/model/constants'

const metaNamespace = 'meta'
const faqNamespace = 'faq'
const languageReplaceRegExp = new RegExp(`${ ALLOWED_LANGUAGES.map(l => `/${ l }`).join('|') }`)

export interface ProductConfiguration extends HasTotalReview, HasPriceValue, HasCurrency {
}

export interface SelfStorageConfiguration extends HasImage, HasCountry, HasCoordinates, HasPrice, HasTotalReview, HasCity {
}

export interface ArticleJsonLdConfiguration extends HasCreatedDate, HasBody, HasTotalReview, HasPrice {
}

export interface OrganizationJsonLdConfiguration extends HasTotalReview, HasImage {
}

export interface BreadcrumbConfiguration extends PathEntry {
  label?: string
}

export interface PageExtensionsConfiguration extends MayHaveImage {
  page: string
  titleGetter?: () => string
  descriptionGetter?: () => string
  params?: Ref<NamedValue>
  product?: ProductConfiguration
  article?: ArticleJsonLdConfiguration
  organization?: OrganizationJsonLdConfiguration
  selfStorage?: SelfStorageConfiguration
  website?: boolean
  breadcrumbs?: BreadcrumbConfiguration[] | ((title: string, description: string, params: NamedValue) => BreadcrumbConfiguration[])
  customJsonLd?: string[]
  faq?: string | boolean
  excludeAlternate?: boolean
  preloadImages?: string[]
}

export default function usePageExtensions({
                                            page,
                                            titleGetter,
                                            descriptionGetter,
                                            params,
                                            image,
                                            product,
                                            organization,
                                            article,
                                            selfStorage,
                                            breadcrumbs,
                                            website,
                                            customJsonLd,
                                            faq,
                                            excludeAlternate,
                                            preloadImages
                                          }: PageExtensionsConfiguration) {
  const {
    language: appLanguage,
    tp,
    t,
    getLocaleMessage
  } = useI18n$(page)
  const { currentRoute } = useRouter()

  const breadcrumbEntries = ref<BreadcrumbEntry[]>([])

  const faqEntries = computed(() => faq ? createFaqEntries(appLanguage.value, params?.value) : [])

  const extractPath = (path: string) => path.replace(languageReplaceRegExp, '')
  const createMetaTagOptions = (configuration: MetaHeaderConfiguration) => {
    if (image) {
      return createMetaHeaders(configuration)
    }
    return createDescriptionMetaHeader(configuration.description)
  }

  const createMeta = (language: Language) => {
    const paramValue = params?.value || {} as NamedValue
    const path = currentRoute.value.path
    const query = currentRoute.value.query
    const title = titleGetter?.() || tp(`${ metaNamespace }.title`, paramValue) as string
    const description = descriptionGetter?.() || tp(`${ metaNamespace }.description`, paramValue) as string
    const alternateName = t('global.meta.alternateName', paramValue) as string
    const contactType = t('global.meta.contactType', paramValue) as string
    const slogan = t('global.meta.contactType', paramValue) as string
    const url = `${ SITE_URL }${ path }`

    const script: Container<any> = {}
    let index = 1
    const createJsonLd = (value: string) => {
      script[`ldJson${ index++ }`] = {
        type: 'application/ld+json',
        innerHTML: value
      }
    }
    if (product) {
      createJsonLd(createProductJsonLd({
        title,
        description,
        url,
        alternateName,
        language,
        image,
        ...product
      }))
    }
    if (organization) {
      createJsonLd(createOrganizationJsonLd({
        description,
        url,
        alternateName,
        contactType,
        language,
        ...organization
      }))
    }
    if (website) {
      createJsonLd(createWebSiteJsonLd({
        description,
        url,
        alternateName,
        language,
      }))
    }
    if (selfStorage) {
      createJsonLd(createSelfStorageJsonLd({
        title,
        description,
        url,
        ...selfStorage
      }))
    }
    if (breadcrumbs) {
      let breadcrumbsValue = typeof breadcrumbs === 'function' ? breadcrumbs(title, description, paramValue) : breadcrumbs
      const rootLabel = t('global.pages.main')
      breadcrumbsValue = [{
        label: rootLabel,
        name: rootLabel,
        path: language
      },
        ...breadcrumbsValue
      ]
      createJsonLd(createBreadcrumbJsonLd(breadcrumbsValue))
      breadcrumbEntries.value = createBreadcrumbEntries(breadcrumbsValue)
    }
    if (customJsonLd) {
      customJsonLd.forEach(cj => createJsonLd(cj))
    }
    if (image) {
      if (article) {
        createJsonLd(createArticleJsonLd({
          title,
          description,
          url,
          image,
          ...article
        }))
      }
      createJsonLd(createImageJsonLd({
        title,
        image
      }))
    }
    if (faq) {
      createJsonLd(createFAQJsonLd(faqEntries.value))
    }

    return useMeta({
      title,
      meta: createMetaTagOptions({
        title,
        description,
        image: image || '',
        url,
        alternateName,
        slogan
      }),
      link: { ...createLanguageLinks(extractPath(path), query, language, excludeAlternate), ...createPreloadImages(preloadImages || []) },
      script
    })
  }
  const createFaqEntries = (language: Language, params?: NamedValue) => {
    const name = typeof faq === 'string' ? faq : 'common'
    // @ts-ignore
    const messages = (getLocaleMessage(language)?.[faqNamespace]?.[name] || {}) as { [key: string]: FAQEntry }
    return Object.keys(messages)
      .map(key => {
        return params ? {
          question: t(`${ faqNamespace }.${ name }.${ key }.question`, params),
          answer: t(`${ faqNamespace }.${ name }.${ key }.answer`, params)
        } : messages[key]
      }) as FAQEntry[]
  }
  const createBreadcrumbEntries = (breadcrumbs: BreadcrumbConfiguration[]) => {
    let path = ''

    return breadcrumbs
      .map(b => {
        path += `/${ b.path }`

        return {
          label: b.label || path,
          to: path
        }
      })
  }

  watch(appLanguage, newLanguage => createMeta(newLanguage))

  createMeta(appLanguage.value)

  return {
    faqEntries,
    breadcrumbEntries: computed(() => breadcrumbEntries.value)
  }
}
