import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row items-start" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "text-large text-weight-bold" }
const _hoisted_6 = { class: "col-auto q-pl-sm blog-preview__created-date" }
const _hoisted_7 = {
  key: 0,
  class: "text-grey-7"
}
const _hoisted_8 = { class: "ellipsis-3-lines q-pt-md" }
const _hoisted_9 = { class: "col-auto text-right q-pt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_responsive = _resolveComponent("q-responsive")!
  const _component_large_btn = _resolveComponent("large-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_responsive, {
    ratio: 4.5 / 5,
    class: "fit"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        i18n: _ctx.$options.name,
        class: "blog-preview fit column rounded-borders-large shadow-10"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_responsive, {
            ratio: 5 / 3,
            class: "full-width"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: _ctx.to,
                class: "full-width"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    alt: _ctx.st(_ctx.blogInstance.title),
                    src: _ctx.loadingError ? '/images/blog-fallback.jpg' : _ctx.blogInstance.image,
                    class: "blog-preview__img fit",
                    loading: "lazy",
                    onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoadError && _ctx.onLoadError(...args))),
                    onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoadFinished && _ctx.onLoadFinished(...args)))
                  }, null, 40, _hoisted_1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "column col" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.st(_ctx.blogInstance.title)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.blog.createdDate)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.fdf(_ctx.blog.createdDate)), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.st(_ctx.blogInstance.description)), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_large_btn, {
                  label: _ctx.tp('link'),
                  to: _ctx.to,
                  sync: ""
                }, null, 8, ["label", "to"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["i18n"])
    ]),
    _: 1
  }))
}