import { PreFetchOptions } from '@quasar/app-webpack'
import useBlogHome from 'stores/blogHome'
import useUserDetails from 'stores/userDetails'
import useAppData from 'stores/appData'
import useUserPreview from 'stores/userPreview'
import useUserLocation from 'stores/userLocation'
import { handleNotFound } from 'src/compositions/prefetch/common/errors'

export default async function blogPrefetch(options: PreFetchOptions<any>) {
  const userDetailsState = useUserDetails(options.store)
  const blogHomeState = useBlogHome(options.store)
  const appDataState = useAppData(options.store)
  const locationState = useUserLocation(options.store)
  const previewState = useUserPreview(options.store)

  const path = options.currentRoute.params.blog as string

  if (!path) {
    handleNotFound(options)
    return
  }

  await Promise.all([
    blogHomeState.loadOne(options.currentRoute.params.blog as string, userDetailsState.language),
    appDataState.loadReviews(userDetailsState.language),
    previewState.loadAllNearest(locationState.location),
  ])
}
