
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { LoadingBar } from 'quasar'
import { IGNORE_AJAX_URLS } from 'src/model/constants'
import useAuthorization from 'src/compositions/authorization'
import useEnvironment from 'src/compositions/environment'
import { useIcons } from 'src/config/icons'
import useCityAds from 'src/compositions/cityAds'
import { initAppUrl } from 'src/functions/app'

export default defineComponent({
  name: 'App',
  setup() {
    const { setup } = useIcons()
    const { app } = useEnvironment()
    const { tryLoadUser } = useAuthorization()
    const { tryInit } = useCityAds()
    const router = useRouter()

    onMounted(() => {
      if (!app) {
        tryLoadUser()
      }
      router.isReady().then(async () => {
        tryInit()
        if (app) {
          await initAppUrl(router)
        }
      })
      LoadingBar.setDefaults({
        // @ts-ignore
        hijackFilter(url: string) {
          return !IGNORE_AJAX_URLS.find(ignoredUrl => url.startsWith(ignoredUrl))
        }
      })
    })

    setup()
  }
})
