import { Amount, Container, Currency, Phone } from 'src/model/common.model'

const DELIMITER_REGEX = /\B(?=(\d{3})+(?!\d))/g

const CURRENCY_TO_SIGN: Container<string> = {
  USD: 'US$',
  RUB: '₽',
  GBP: '£',
  EUR: '€',
  MXN: 'MX$', //PESO
  TRY: '₺', //LIRA
  JPY: '¥',  //YEN
  CNY: '¥',  //YAN
  THB: '฿',  //BAHT
  KRW: '₩',  //WON
  VND: '₫',  //DONG
  INR: '₹',  //RUPEE
  ILS: '₪',  //SHEKEL
  EGP: 'E£',  //EGYPT POUND
  AED: 'AED',  //UAE
  ARS: 'AR$',  //ARGENTINA DOLLAR
  BRL: 'R$',  //BRAZIL DOLLAR
  CAD: 'CA$',  //CANADA DOLLAR
  CLP: 'CL$',  //CHILI DOLLAR
  COP: 'COL$',  //COLOMBIA DOLLAR
  CUP: '₱',  //CUBA DOLLAR
  ECS: 'ECS',  //ECUADOR
  IDR: 'Rp',  //INDONESIA
  MYR: 'RM',  //MALAYSIA DOLLAR
  PEN: 'S/',  //PERU DOLLAR
  SAR: 'SAR',  //SAUDI ARABIA
  SGD: 'SG$',  //SINGAPORE
  VES: 'Bs',  //VENEZUELA
  ZAR: 'R',  //SOUTH AFRICA
  AUD: '$',  //AUSTRALIA DOLLAR
  HKD: 'HK$', //HONG KONG DOLLAR
  PLN: 'zł', //POLISH ZLOTI
  HUF: 'Ft', //HUNGARIAN FORINT
  AMD: '֏', //ARMENIAN DRAM,
  UZS: 'Som', //UZBEKISTAN SOM
}

export function currencyToSign (currency: Currency): string {
  return CURRENCY_TO_SIGN[currency] || '$'
}

export function currencyToDelimiter (currency: Currency): string {
  switch (currency) {
    case 'RUB':
      return ' '
    default:
      return ','
  }
}

export function formatAmount (amount: Amount, fractions: number = 2, useSymbol: boolean = true): string {
  fractions = amount.value % 1 === 0 ? 0 : fractions
  const delimiter = currencyToDelimiter(amount.currency)
  const price = amount.value.toFixed(fractions).replace(DELIMITER_REGEX, delimiter)
  const currency = useSymbol ? currencyToSign(amount.currency) : amount.currency
  switch (amount.currency) {
    case 'RUB':
    case 'AED':
      return `${price}${currency}`
    default:
      return `${currency}${price}`
  }
}

export function formatPhone (phone: Phone): string {
  return `${phone.code}${phone.number}`
}

export function formatWithCenteredDash(value: string | undefined | null): string {
  if (!value) return ''
  if (value.length < 5 || value.includes('-')) return value
  const centerIndex = Math.floor((value.length) / 2)
  return value.substring(0, centerIndex) + '-' + value.substring(centerIndex)
}