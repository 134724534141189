import { Router } from 'vue-router'
import { App } from '@capacitor/app'
import { APP_ANDROID_URL, APP_IOS_URL, SITE_URL } from 'src/model/constants'

function extractUrl(url: string) {
  if (url.startsWith(APP_ANDROID_URL)) {
    url = url.substring(APP_ANDROID_URL.length)
  } else if (url.startsWith(APP_IOS_URL)) {
    url = url.substring(APP_IOS_URL.length)
  } else {
    url = url.substring(SITE_URL.length)
  }
  return url
}

export async function initAppUrl(router: Router) {
  const launchUrl = (await App.getLaunchUrl())?.url
  if (launchUrl) {
    const futureRoute = router.resolve(extractUrl(launchUrl))
    await router.push({...futureRoute, query: {...futureRoute.query, changeUserLocation: ''}})
  }

  App.addListener('appUrlOpen', async (event: any) => {
    let url = extractUrl(event.url)
    console.log('url', url)
    const currentRoute = router.currentRoute.value
    const futureRoute = router.resolve(url)
    if (currentRoute.fullPath !== futureRoute.fullPath) {
      await router.push({...futureRoute, query: {...futureRoute.query, changeUserLocation: ''}})
    }
  })
    .catch(error => console.error(error))
}
