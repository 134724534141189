import { PreFetchOptions } from '@quasar/app-webpack'
import useUserDetails from 'stores/userDetails'
import { ErrorNotFound } from 'src/model/common.model'
import { ERROR_404 } from 'pages/names'

export const handleNotFound = (options: PreFetchOptions<any>) => {
  const code = useUserDetails(options.store).language === 'tr' ? 410 : 404
  if (options.ssrContext) {
    throw new ErrorNotFound(code)
  } else {
    return options.redirect({ name: ERROR_404 }, code)
  }
}
