import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 xs" }
const _hoisted_4 = { class: "row items-center justify-center" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "row items-center" }
const _hoisted_7 = { class: "col-12 col-sm" }
const _hoisted_8 = { class: "row items-center justify-center page-footer__user-links_row" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "row items-center" }
const _hoisted_14 = { class: "col-12 col-sm-3 qeepl-col q-py-sm" }
const _hoisted_15 = { class: "row items-center justify-center" }
const _hoisted_16 = { class: "col-auto" }
const _hoisted_17 = { class: "col-12 col-sm" }
const _hoisted_18 = { class: "row items-center" }
const _hoisted_19 = { class: "col-12 col-sm-auto rating-col q-py-sm" }
const _hoisted_20 = { class: "row items-end" }
const _hoisted_21 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_image = _resolveComponent("icon-image")!
  const _component_toolbar_title = _resolveComponent("toolbar-title")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_qeepl_rating = _resolveComponent("qeepl-rating")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_container_template = _resolveComponent("container-template")!
  const _component_q_footer = _resolveComponent("q-footer")!

  return (_openBlock(), _createBlock(_component_q_footer, { class: "bg-white text-black page-footer" }, {
    default: _withCtx(() => [
      _createVNode(_component_container_template, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, { class: "q-py-sm" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_icon_image, {
                          to: _ctx.toRoot(),
                          alt: "Qeepl logo",
                          height: "50",
                          name: "logo",
                          width: "96"
                        }, null, 8, ["to"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_toolbar_title, {
                    class: "col-auto gt-xs",
                    size: "md"
                  }),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anonymousPages, (page) => {
                        return (_openBlock(), _createBlock(_component_q_btn, {
                          key: page,
                          label: _ctx.tp(page),
                          to: _ctx.toPage(page),
                          class: "col-4 col-sm-auto text-transform-none",
                          color: "black",
                          flat: ""
                        }, null, 8, ["label", "to"]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_q_separator, { class: "q-my-sm" })
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-auto col-sm" }, " 2021 - 2025 qeepl.com ", -1)),
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_q_btn, {
                              icon: _ctx.isWorldContext ? _ctx.matInstagram : _ctx.matTelegram,
                              href: _ctx.isWorldContext ? _ctx.INSTAGRAM_URL : _ctx.TELEGRAM_URL,
                              flat: "",
                              size: "lg",
                              padding: "none",
                              color: "primary",
                              target: "_blank"
                            }, null, 8, ["icon", "href"])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conditionPages, (page) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: page,
                              class: "col-12 text-center"
                            }, [
                              _createVNode(_component_q_btn, {
                                label: _ctx.tp(page),
                                to: _ctx.toPage(page),
                                class: "text-transform-none",
                                color: "grey-6",
                                flat: "",
                                padding: "sm",
                                size: "md"
                              }, null, 8, ["label", "to"])
                            ]))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createVNode(_component_qeepl_rating, {
                              size: _ctx.$q.screen.xs ? 'xs' : 'sm',
                              dense: ""
                            }, null, 8, ["size"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}