import { BaseUserProjection } from 'src/model/user.model'
import {
  Amount,
  ChargeType,
  Coordinates,
  Currency,
  HasPriceContainer,
  ItemSizeContainer,
  MayHavePriceContainer,
  PayoutSettings,
  ServiceType,
  ServiceTypeAlias,
  ServiceTypeAware
} from 'src/model/common.model'
import {
  ALLOWED_LANGUAGES,
  DEFAULT_DAY_PRICE,
  DEFAULT_HOUR_PRICE,
  DEFAULT_LARGE_DAY_PRICE,
  DEFAULT_LARGE_HOUR_PRICE,
  DEFAULT_LARGE_MONTH_PRICE,
  DEFAULT_MONTH_PRICE,
  DEFAULT_SMALL_DAY_PRICE,
  DEFAULT_SMALL_HOUR_PRICE,
  DEFAULT_SMALL_MONTH_PRICE,
  KM_TO_MI_MULTIPLIER,
  M_TO_FT_MULTIPLIER,
  OUT_OF_WORLD_COUNTRIES
} from 'src/model/constants'
import { Language } from 'src/model/language.model'

export function openExternalUrl(url: string) {
  location.href = url
}

export function replaceSnake(text: string): string {
  return text.toLowerCase().replace(/_/g, '-')
}

function capitalize(value: string) {
  if (value.length) {
    return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
  }
  return ''
}

export function extractUserName(name: string): { firstName: string, lastName: string } {
  const names = name.split(' ')
  if (names.length === 1) {
    return { firstName: capitalize(names[0]), lastName: '' }
  }
  return { firstName: capitalize(names[0]), lastName: capitalize(names[1]) }
}

export function formatUser(user: BaseUserProjection): string {
  return `${ user.login }${ user.firstName ? ` [${ user.lastName ? `${ user.lastName } ` : '' }${ user.firstName }]` : '' }`
}

export function usePaypalAccount(country: string | null) {
  return country && (['BR', 'GE'].includes(country))
}

export function useStripeAccount(country: string | null) {
  return !country || !OUT_OF_WORLD_COUNTRIES.includes(country)
}

export function extractPayoutSettings<T extends PayoutSettings>(value: T): PayoutSettings {
  if (value.paypal) {
    return {
      paypal: value.paypal
    }
  } else if (value.stripe) {
    return {
      stripe: value.stripe
    }
  } else if (value.account) {
    return {
      legalName: value.legalName,
      account: value.account
    }
  } else if (value.card) {
    return {
      card: value.card
    }
  } else {
    return {}
  }
}

export function isPayoutSettingsSet<T extends PayoutSettings>(value: T): boolean {
  if (value.stripe) {
    return !!value.stripe.accountId && value.stripe.onboardingFinished
  } else if (value.account) {
    return value.account.accountNumber?.length == 20 && value.account.bankBic?.length == 9 &&
      ((value.account.inn?.length == 10 && value.account.kpp?.length == 9) || value.account.inn?.length == 12)
  } else if (value.card) {
    const length = value.card.cardNumber?.length ?? 0
    return length >= 8 && length <= 19
  } else {
    return false
  }
}

export function keyToPath(key: string): string {
  return key ? key.toLowerCase().replace(/_/g, '-') : ''
}

export function pathToKey(path: string): string {
  return path ? path.toUpperCase().replace(/-/g, '_') : ''
}

export function isPath(path: string): boolean {
  return path ? replaceSnake(path) === path : false
}

export function filterLowerCase(text: string, filterToken: string): boolean {
  return text.toLowerCase().indexOf(filterToken.toLowerCase()) >= 0
}

export function isWorldCountry(key: string) {
  return !OUT_OF_WORLD_COUNTRIES.includes(key)
}

export function extractLanguage(url: string): Language | undefined {
  if ((!url || url === '/' || url.length < 3)) {
    return undefined
  } else {
    const language = url.substring(1, 3) as Language
    return ALLOWED_LANGUAGES.includes(language) ? language : undefined
  }
}

export function createMapLink(coordinates: Coordinates, country: string): string {
  if (OUT_OF_WORLD_COUNTRIES.includes(country)) {
    return `https://yandex.ru/maps/?pt=${ coordinates.longitude },${ coordinates.latitude }&z=16&l=map`
  }
  return `http://www.google.com/maps/place/${ coordinates.latitude },${ coordinates.longitude }`
}

export function createAmount(value: number, currency: Currency): Amount {
  return { value, currency }
}

export function convertToFt(number: number): number {
  return number * M_TO_FT_MULTIPLIER
}

export function convertFromFt(number: number): number {
  return Number((number / M_TO_FT_MULTIPLIER).toFixed(1))
}

export function convertToMI(number: number): number {
  return Number((number / KM_TO_MI_MULTIPLIER).toFixed(1))
}

export function convertFromMI(number: number): number {
  return Number((number / KM_TO_MI_MULTIPLIER).toFixed(1))
}

export function isDefaultServiceType(serviceType: ServiceType): boolean {
  return serviceType === 'DEFAULT'
}

export function isSelfServiceType(serviceType: ServiceType): boolean {
  return serviceType === 'SELF'
}

export function isMonthly(chargeType: ChargeType): boolean {
  return chargeType === 'MONTHLY'
}

export function isHourly(chargeType: ChargeType): boolean {
  return chargeType === 'HOURLY'
}

export function isDaily(chargeType: ChargeType): boolean {
  return chargeType === 'DAILY'
}

export function isDefault<DEFAULT, SELF>(serviceTypeAware: ServiceTypeAware<DEFAULT, SELF>): boolean {
  return isDefaultServiceType(serviceTypeAware.type)
}

export function isSelf<SELF>(serviceTypeAware: ServiceTypeAware<unknown, SELF>): boolean {
  return isSelfServiceType(serviceTypeAware.type)
}

export function doWhen<T, DEFAULT, SELF>(
  serviceTypeAware: ServiceTypeAware<DEFAULT, SELF>,
  defaultBlock: (default_: DEFAULT) => T,
  selfBlock: (self: SELF) => T
): T {
  switch (serviceTypeAware.type) {
    case 'DEFAULT':
      return defaultBlock(serviceTypeAware.default!)
    case 'SELF':
      return selfBlock(serviceTypeAware.self!)
  }
}

export function whenDefault<DEFAULT, SELF>(
  serviceTypeAware: ServiceTypeAware<DEFAULT, SELF>,
  defaultBlock: (default_: DEFAULT) => void
) {
  if (serviceTypeAware.type === 'DEFAULT') {
    defaultBlock(serviceTypeAware.default!)
  }
}

export function whenSelf<SELF>(
  serviceTypeAware: ServiceTypeAware<unknown, SELF>,
  selfBlock: (self: SELF) => void
) {
  if (serviceTypeAware.type === 'SELF') {
    selfBlock(serviceTypeAware.self!)
  }
}

export function whenDefaultOnly<T, DEFAULT, SELF>(
  serviceTypeAware: ServiceTypeAware<DEFAULT, SELF>,
  defaultBlock: (default_: DEFAULT) => void
) {
  return doWhen(serviceTypeAware, defaultBlock, () => {
    throw new Error('Not a default order')
  })
}

export function whenSelfOnly<T, DEFAULT, SELF>(
  serviceTypeAware: ServiceTypeAware<DEFAULT, SELF>,
  selfBlock: (self: SELF) => void
) {
  return doWhen(serviceTypeAware, () => {
    throw new Error('Not a self order')
  }, selfBlock)
}

export function aliasToServiceType(alias: ServiceTypeAlias): ServiceType {
  switch (alias) {
    case 'LUGGAGE':
    case 'LOCKERS':
    case 'EXCHANGE':
      return 'DEFAULT'
    case 'SELF':
    case 'SKI_STORAGE':
      return 'SELF'
  }
}

export function getAppStoreLink(lang: Language) {
  let lang_: string = lang

  switch (lang) {
    case 'en': lang_ = 'us'; break
    case 'es': lang_ = 'mx'; break
    case 'zh': lang_ = 'cn'; break
    case 'ja': lang_ = 'jp'; break
  }

  return `https://apps.apple.com/${ lang_ }/app/qeepl/id1588900069`
}
